.p-card-container {
    display: flex; /* Add this line */
    flex-direction: column; /* Add this line */
    text-align: center;
    color:whitesmoke;
    background-color:#525252;
    border: 1px solid black; /* Add solid border */
    width: 200px;
    height: 200px;
    border-radius: 5px;
    margin:0px; /* Add margin for spacing */
    padding: 10px; /* Add padding for content spacing */
    box-sizing: border-box; /* Include border and padding in width and height */
    overflow: hidden;
}

.p-card-container:hover {
    background-color: #9c7e7e;
}

.proposal-id {
    font-family: Special Elite;
    background-color: black;
    color: white;
    border-radius:5px;
}

.proposal-title{
    font-family: Special Elite;
    font-size: 1.2rem;
}

.proposal-submitter-container{
    margin-top: auto; /* Add this line to push the submitter to the bottom */
}

.badge {
    display: inline-block;
    padding: 0.25em 0.5em;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    background-color: #007bff; /* Change this color to whatever you desire */
    color: white;
}

/* If you want to color-code categories, you can add additional styles */
.badge-governance {
    background-color: #007bff;
}

.badge-moduluszk {
    background-color: #28a745;
}

.badge-other {
    background-color: #ffc107;
}

.badge-letter {
    background-color: #a00ed5;
}

