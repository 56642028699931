body, html {
    margin: 0;
    padding: 0;
    background-color:#313131;
}


.pro-container {
    text-align: center;
    width: 100%;
    background-color:#313131;
    color: whitesmoke;
    padding-top:30px;
}

.pr-sub-container {
    border: 3px solid black;
    width:90%;
    margin: auto;
    margin-bottom:50px;
    margin-top:0px;
    border-radius:5px;
    max-width:1000px;
}

.headline {
    font-size: 2rem;
    font-family: Benguiat;
    padding-top:10px;
    margin-bottom:0px;
    background-color:black;
    color:white;
}

.sub-headline {
    font-size: 2rem;
    font-family: Benguiat;
    padding-top:10px;
    padding-bottom: 10px;
    background-color:black;
    color:white;
    margin-top:0px;
    margin-bottom:0px;
}

.author-headline {
    font-size: 1.2rem;
    font-family: Benguiat;
    padding-top:10px;
    padding-bottom: 10px;
    background-color:black;
    color:white;
    margin-top:0px;
}

.description {
    font-family: Special Elite;
    text-align:left;
    width: 80%;
    padding-left:10px;
    padding-top:20px;
    font-size:0.9rem;
    color:whitesmoke;
}

.comment {
    width:60%;
    margin: auto;
    margin-bottom: 10px;
    margin-top: 20px;
}

.discussion-headline {
    font-size: 2rem;
    font-family: Benguiat;
    padding-top:10px;
    margin-top: 30px;
    color:whitesmoke;
}

.revoloading{
    width: 50%;
    margin: auto;
    margin-top: 300px;
    font-family: Special Elite;
    font-size: 2rem;
    text-align: center;
}

.amendment{
    min-height:150px;
    margin:auto;
    margin-top:-10px;
    width:100%;
    border-bottom: 3px solid black;
    padding-top:10px;
}

.amendment-buttons {
    margin: 5px;
    border:1px solid whitesmoke;
    border-radius: 5px;
    background-color: black;
    color: whitesmoke;

}

.proposalfooter {
    text-align: right;
    font-family: Special Elite;

}

.proposal-edit {
    min-height:400px;
    min-width: 100%;
    margin:auto;
    margin-top: -10px;
    background-color:#313131;
    color:whitesmoke;
    border-bottom: 3px solid black;
    border-radius:0px;
}

.snap {
    padding: 5px;
}

@media (max-width: 900px){
    .sub-headline{
        font-size:1.2rem;
    }

}