.disclaimer{
    color: white;
    background-color:red;
    font-family: Special Elite;
    font-size:1rem;
    text-align:center;
}

.pitch-table-header{
    text-align: center;
    color:whitesmoke;
    font-family: Benguiat;
    padding-top:20px;
}