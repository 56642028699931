.pr-container {
    margin: auto;
    padding: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color:#313131;
    color: whitesmoke;
}

.proposal-headline {
    text-align:center;
    font-family: Benguiat;
    margin-bottom: 40px; /* Add space after headline */
}

.label-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.label-container input[type="text"] {
    min-width:100%;
    margin-top: 10px;
    margin-bottom: 40px; /* Add space after input fields */
}

.label-container .quill {
    min-width:100%;
    margin-top: 0px; /* adjust as needed */
    margin-bottom: 20px; /* adjust as needed */
}

.headline {
    width: auto;
}

.quill .ql-editor {
    min-height: 400px; /* adjust as needed */
}

input[type="submit"] {
    margin-top: 5px; /* Add space before submit button */
}

.sub-head{
    font-family: Benguiat;
    padding-top:15px;
}

.quill-wrapper {
    width:600px;
    margin-top: -25px; /* try negative margin to decrease space */
    margin-bottom: 20px; /* adjust as needed */
}

.pr-input{
    background-color: #313131;
    border:1px solid whitesmoke;
    color:whitesmoke;
}

.description{
    color:whitesmoke;
}

@media (max-width:619px){
    .quill-wrapper{
        width: 90%;
    }
}