.p-container {
    text-align: center;
    width: 90%;
    margin:auto;
}

.sub-container {
    border: 3px solid black;
    width:80%;
    margin: auto;
    margin-bottom:50px;
    margin-top:30px;
    border-radius:5px;
    max-width: 1000px;
}

.pitch-headline {
    font-size: 2rem;
    font-family: Benguiat;
    padding-top:10px;
    margin-bottom:0px;
    background-color:black;
    color:whitesmoke;
    text-align: Center;
}

.project-description {
    font-family: Special Elite;
    text-align:left;
    width: 90%;
    margin:auto;
    padding-top:0px;
    color:whitesmoke;
}

.comment {
    width:60%;
    margin: auto;
    margin-bottom: 10px;
    margin-top: 20px;
}

.pitch-header{
    font-size: 1.2rem;
    font-family: Benguiat;
    padding-top:30px;
    padding-bottom:30px;
    color:whitesmoke;
    text-align: Center;

}

.project-socials {
    font-family: Special Elite;
    text-align:center;
    width: 90%;
    margin:auto;
    padding-bottom:10px;
    color:whitesmoke;
}

.file-icon{
    margin:auto;
    margin-bottom:20px;
    margin-top:20px;
    text-align:center;
}

.vote {
    font-family:Special Elite;
    padding:5px;
    margin:auto;
}

.down{
    margin:10px;
    margin-bottom: 30px;
    text-align: Center;}

.vote-header {
    font-family: Special Elite;
    color: whitesmoke;
    text-align: Center;
}

.discussion-headline{
    text-align: center;
}

.vote-arrows{
    text-align: center;
}
