.main{
   background-color: #313131;
   color:whitesmoke;
   font-family: Benguiat; 
}

.cip{
    background-color:#313131;
}

.lp-header{
width:90%;
margin:auto;
text-align:center;
font-size: 3rem;
padding-bottom:40px;
font-weight:bold;
padding-top: 30px;
}

.card-img-top{
    width:250px;
    display: block;   /* Convert image to block level element */
    margin-left: auto;
    margin-right: auto;
}

@media (max-width:655px){
    .lp-header{
        font-size:2rem;
    }
}