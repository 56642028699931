.footer-container{
    padding-top: 200px;
    padding-bottom:50px;
    font-family: Benguiat;
    color: whitesmoke;
    text-align: center;
    background-color: #313131;
}

.twitter-logo {
    margin-right:10px;
    color:whitesmoke;
}

.website-logo { 
    color:whitesmoke;

}