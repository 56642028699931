.cip-header {
    font-family: Benguiat;
    text-align:center;
    font-size: 2em;
    margin-top: 20px;;
}

.button-container {
    text-align: center;
    margin-bottom: 10px; /* Adjust as needed */
}

.enter {
    display: inline-block;
    margin: auto;
    width:50%;
    padding:1%;
}

.submit{
    display: inline-block;
    margin: auto;
    width:50%;
    padding:1%;
}

.enter-btn {
    width:100%;
    font-family: Benguiat;
    margin:auto;
}

.submit-btn {
    width:100%;
    font-family: Benguiat;
    margin:auto;
}


.about {
    display: block;
    text-align: center;
    color: whitesmoke;
    font-family: Special Elite;
}

.about-box{
    display:block;
    color:whitesmoke;

}

.card-style{ 
   background-color: #414141;
   color: whitesmoke;
   border: 1px solid whitesmoke;
   border-radius:5px;
   margin:1%
}
