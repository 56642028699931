.main-log-container{
    width:60%;
    margin: auto;
    text-align: center;
    padding-top:50px;
    color: whitesmoke;
    
}

.login-header {
    font-family: Benguiat;
    font-size: 3rem;
    padding-bottom: 80px;
}

.mail-input-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 60%;
    margin-bottom: 0px;
    margin:auto;
    justify-content: center; /* This will center its children vertically */
    align-items: center; 
    
}

.email {
    font-family: Benguiat;
}

.google {
    font-family: Benguiat;
    padding-top:25px;
}

.submit-button {
    width: 70px;
    color:white;
    background-color:black;
}

.button-container{
    margin-bottom: 10px;
    margin-top: 5px;
}

.ggl-btn {
    width:40%;
    margin-top:10px;
    max-width:100px;
    min-width: 60px;
}

.login-form{
    border: 3px solid black;
    border-radius: 5px;
    padding:30px;
    width: 60%;
    margin:auto;
}

.login-button-style {
    width: 100%;
}

@media (max-width: 900px){
    .main-log-container{
       width: 90%;
    }

    .login-form{
        width:80%
    }

    .login-header{
        font-size:2rem;
    }
}