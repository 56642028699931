.main-box{
    width:60%;
    margin:auto;
    border:3px solid black;
    margin-top:20px;
    border-radius:5px;
    max-width:900px;
}

.head{
    height:30px;
    text-align:right;
    margin-bottom:5px;
    border-bottom: 1px solid black;
    color:white;
    background-color:black;
    display: flex;
    justify-content: space-between;
    font-family: Special Elite;
    
}

.text{
    text-align:left;
    padding:10px; 
    font-size: 0.8rem;
}

.username{
    padding-left:5px;
}

.date {
    padding-right:5px;
}

.replybox{
    min-height: 150px;
    width:60%;
}

.textbox{
    min-height:150px;
    margin:auto;
    margin-top: 10px;
    width:100%;
    background-color:#313131;
    color:whitesmoke;
    border: 3px solid black;
    border-radius:5px;
    padding-top:10px;
}

@media (max-width:900px) {
    .main-box{
        width:90%;
    }

    .replybox{
        width:100%;
    }

    .username{
        font-size: 0.8rem;
    }

    .date{
        font-size:0.8rem;
    }

}