.archive{
    font-size: 0.8rem;
    font-family: Special Elite;
    color:whitesmoke;
    background-color: black;
    border-radius: 5px;
    border:1px solid whitesmoke;
    margin:2px;

}

.comment-footer {
border-top:1px solid black;
text-align:right;

}

.replying{
    text-align:left;
    font-family: Special Elite;
    font-size: 0.8rem;
    font-style:italic;
    margin-left:2px;
    color:whitesmoke;

}

.text{
    font-size: 0.8rem;
    color: whitesmoke;
}