.landing-page-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin-left: 180px;
  }
  
  .logo-image {
    width: 600px;
    height: auto;
    margin-top: 600px;
  }
  
  .welcome-heading {
    font-size: 78px;
    margin-top: 20px;
    margin-bottom: 40px;
    font-family: "Benguiat";
  }
  
  .site-description {
    font-size: 16px;
    margin-top: 10px;
  }
  
  .button-container {
    margin-top: 20px;
    margin-bottom: 100px;
  }
  
  .action-button {
    display: inline-block;
    padding: 10px 20px;
    margin-right: 50px;
    background-color: #000000;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
  }
  
  .site-details {
    padding-top: 30px;
    width: 60%;
    margin:auto;
    text-align: justify;
    font-family: "Special Elite";
    color: whitesmoke;

  }

  .site-details p {
    line-height: 1.2;
    margin-bottom: 35px;
  }
  
  .site-details ul {
    line-height: 1.2;
    list-style-type: disc;
    margin-bottom:50px;
  }

  .about{
    display: block;
    text-align:center;
    color:whitesmoke;;
    font-family: Special Elite;
}
  
  