.proposal-list {
    display:flex;
    flex-direction: row;
    justify-content:flex-start;
    align-items: stretch;
    align-content: center;
    padding-top:70px;
    padding-left:100px;
    flex-wrap: wrap; /* Allow cards to wrap to the next line */
    gap: 20px; /* Add gap between cards */
    background-color:#313131;

}

.pl-header{
    font-family: Benguiat;
    text-align: center;
    font-size: 4rem;
    font-weight: bold;
    padding-top:40px;
    background-color:#313131;
    color:whitesmoke;
    margin-bottom:0px;
}

.proposal-submitter{
    font-family: Special Elite;
    font-size: 0.8rem;
}

@media (max-width: 640px) {
    .pl-header {
        font-size: 2.5rem;
    }
    
    .proposal-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0;
        gap: 20px;
        box-sizing: border-box;
    }
    
    .proposal-list > div {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 200px; /* Adjust this to your card's width */
        margin: 0 auto; /* Center in the case it doesn't occupy full width */
        padding-top:30px;
    }
}

@media (max-width:800px){
    .pl-header{
        font-size:2.5rem;
    }
}




    
